@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

body {
  @apply font-sans;
  font-family: "Inter", "Poppins", "Outfit", sans-serif !important;
  scroll-behavior: smooth !important;
}

html {
  scroll-behavior: smooth !important;
}

.PhoneInputInput{
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important;
  padding : 17px 0 17px 7px !important;
  font-size : 12px !important;
  border-radius : 0 10px 10px 0 !important
}

.workspaceInput input{
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important;
  padding : 14px 0 14px 7px !important;
  font-size : 13px !important;
  border-radius : 0 10px 10px 0 !important
}
.tenant input{
  padding : 13px 0 13px 6px !important;
}
